import Page from '@/components/Page';
import { getRaffle } from '@/features/Raffle/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useExpand } from '@vkruglikov/react-telegram-web-app';
import { BackButton } from '@vkruglikov/react-telegram-web-app';
import { useNavigate } from 'react-router-dom';
import { getUser, setUser } from '@/modules/User/reducer';
import AnimationSettings from '@/icons/animations/Settings.json'
import Lottie from 'lottie-react';
import { animationSizes, raffleStatusStarted } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { HandySvg } from "handy-svg";
import IconText from "@/icons/settings/text.svg";
import IconTitle from "@/icons/settings/title.svg";
import IconDate from "@/icons/settings/date.svg";
import IconImage from "@/icons/settings/image.svg";
import IconWinners from "@/icons/settings/winners.svg";
import IconTheme from "@/icons/settings/theme.svg";
import IconChannels from "@/icons/settings/channels.svg";
import IconStat from "@/icons/settings/stat.svg";
import IconEnd from "@/icons/settings/end.svg";
import IconRepost from "@/icons/settings/repost.svg";
import IconDelete from "@/icons/settings/delete.svg";
import IconCaptcha from "@/icons/settings/captcha.svg";
import IconUnmember from "@/icons/settings/unmember.svg";
import IconExport from "@/icons/settings/export.svg";
import IconPromote from "@/icons/settings/promote.svg";
import SettingsApi from '../api';
import useEnvInitData from '@/hooks/useEnvInitData'
import Load from '../../../components/Load';
import SettingsItem from '../components/SettingsItem';


export default function SettingsPage() {
	const raffle = useSelector(getRaffle)
	const user = useSelector(getUser)
	const dispatch = useDispatch()

	const { t } = useTranslation()
	const [InitDataUnsafe, InitData] = useEnvInitData();

	const { showBoundary } = useErrorBoundary()
	const [isExpanded, expand] = useExpand();
	const navigate = useNavigate()

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (!raffle || !user.is_creator) showBoundary();

		SettingsApi.init({
			initData: InitData,
			raffle: raffle.id
		}).then(function(result) {
			setLoading(false)
			if (!result.raffle || result.raffle !== raffle.id) {
				showBoundary()
			}

			dispatch(setUser({
				...user,
				is_vip: result.is_vip
			}))

		}).catch((e) => {
			setLoading(false)
			showBoundary(e)
		})

		expand()
	}, [raffle, InitData])

	const back = () => {
		navigate(-1)
	}

	const editCommands = [
		{
			key: 'text',
			color: '#0376FF',
			icon: <HandySvg src={IconText} />,
			vip: true,
			url: 'GiveawayEdit-action-getContent_id-' + raffle.id
		},
		{
			key: 'title',
			color: '#38C557',
			icon: <HandySvg src={IconTitle} />,
			vip: true,
			url: 'GiveawayEdit-action-getTitle_id-' + raffle.id
		},
		{
			key: 'date',
			color: '#FF9400',
			icon: <HandySvg src={IconDate} />,
			vip: false,
			url: 'GiveawayEdit-action-getEndDate_id-' + raffle.id
		},
		{
			key: 'image',
			color: '#2DAAE9',
			icon: <HandySvg src={IconImage} />,
			vip: true,
			url: 'GiveawayEdit-action-getImage_id-' + raffle.id
		},
		{
			key: 'winners',
			color: '#FF3A22',
			icon: <HandySvg src={IconWinners} />,
			vip: true,
			url: 'GiveawayEdit-action-getWinners_id-' + raffle.id
		}
	]

	const anotherCommands = [
		{
			key: 'theme',
			color: '#8F8E95',
			icon: <HandySvg src={IconTheme} />,
			raffleStatus: [10],
			vip: false,
			url: 'GiveawayTheme-id-' + raffle.id
		},
		{
			key: 'channels',
			color: '#31C656',
			icon: <HandySvg src={IconChannels} />,
			raffleStatus: [10],
			vip: true,
			url: 'GiveawayChannels-id-' + raffle.id
		},
		{
			key: 'stat',
			color: '#33AADC',
			icon: <HandySvg src={IconStat} />,
			raffleStatus: [10, 20],
			vip: false,
			url: 'GiveawayStatistics-id-' + raffle.id
		},
		{
			key: 'end',
			color: '#FF8902',
			icon: <HandySvg src={IconEnd} />,
			raffleStatus: [10],
			vip: false,
			url: 'Giveaway-action-endNow_id-' + raffle.id
		},
		{
			key: 'repost',
			color: '#B351DB',
			icon: <HandySvg src={IconRepost} />,
			raffleStatus: [10],
			vip: true,
			url: 'Giveaway-action-repost_id-' + raffle.id
		},
		{
			key: 'delete',
			color: '#527bdd',
			icon: <HandySvg src={IconDelete} />,
			raffleStatus: [10],
			vip: true,
			url: 'GiveawayDelete-id-' + raffle.id
		},
		{
			key: 'captcha',
			color: '#336f55',
			icon: <HandySvg src={IconCaptcha} />,
			raffleStatus: [10],
			vip: true,
			url: 'Giveaway-action-captcha_id-' + raffle.id
		},
		{
			key: 'unmember',
			color: '#FF2C43',
			icon: <HandySvg src={IconUnmember} />,
			raffleStatus: [10],
			vip: true,
			url: 'Giveaway-action-blockMember_id-' + raffle.id
		},
		{
			key: 'export',
			color: '#4f5bd5',
			icon: <HandySvg src={IconExport} />,
			raffleStatus: [10, 20],
			vip: true,
			url: 'Giveaway-action-export_id-' + raffle.id
		},
		{
			key: 'promote',
			color: '#F8B624',
			icon: <HandySvg src={IconPromote} />,
			raffleStatus: [10],
			vip: false,
			url: 'GiveawayPromote'
		},
		{
			key: 'rewin',
			color: '#B351DB',
			icon: <HandySvg src={IconRepost} />,
			raffleStatus: [20],
			vip: true,
			url: 'Giveaway-action-replay_id-' + raffle.id
		},
	]

	const [selectedItem, setSelectedItem] = useState(false)
	const [openItem, setOpenItem] = useState(false)

	return (
    	<Page title={raffle.title}>
			<BackButton onClick={back} />
			{loading ?
				<Load />
			:
				<>
					<div className='boxIntro'>
						<div className='boxIntro_sticker'>
							<Lottie animationData={AnimationSettings} loop={false} style={animationSizes['AnimationSettings']} />
						</div>
						<div className='boxIntro_title'>{ t('features.settings.title') }</div>
						<div className='boxIntro_text'>{ t('features.settings.text') }</div>
					</div>

					{raffle.status === raffleStatusStarted ?
						<>
							<div className='boxTitle'>{ t('features.settings.edit') }</div>
							<div className='boxFolder'>
								{editCommands.map((item) =>
									<button className='boxFolderItem' key={item.key} onClick={() => {
										setOpenItem(true)
										setSelectedItem(item)
									}}>
										<div className='boxFolderItem-icon' style={{background: item.color}}>
											{item.icon}
										</div>
										<div className='boxFolderItem-info'>
											<div className='boxFolderItem-name'>{t('features.settings.commands.' + item.key + '.title')}</div>
										</div>
									</button>
								)}
							</div>
						</>
					: null }

					<div className='boxTitle'>{ t('features.settings.another') }</div>
					<div className='boxFolder'>
						{anotherCommands.map((item) =>
							item.raffleStatus.includes(raffle.status) ?
								<button className='boxFolderItem' key={item.key} onClick={() => {
									setOpenItem(true)
									setSelectedItem(item)
								}}>
									<div className='boxFolderItem-icon' style={{background: item.color}}>
										{item.icon}
									</div>
									<div className='boxFolderItem-info'>
										<div className='boxFolderItem-name'>{t('features.settings.commands.' + item.key + '.title')}</div>
									</div>
								</button>
							: null
						)}
					</div>
					<SettingsItem item={selectedItem} open={openItem} close={() => setOpenItem(false)} />
				</>
			}
	    </Page>
	)
}

export const appName = 'GiveShareBot'

export const testInitDataUnsafe = {
	auth_date: "123",
	chat_instance: "123",
	chat_type: "private",
	hash: "123",
	start_param: "C2g",
	user: {
		id: 123,
		first_name: "Влад",
		last_name: "",
		username: "gvv",
		language_code: "en",
		allows_write_to_pm: true
	}
}

export const raffleStatusStarted = 10;
export const raffleStatusPreend = 15;
export const raffleStatusDone = 20;

export const raffleTypeForAll = 0;
export const raffleTypeForNew = 10;
export const raffleTypeForRef = 20;
export const raffleTypeForPay = 30;

export const parentTypeChannel = 0;
export const parentTypeMember = 10;
export const parentTypeGiveshare = 100;

export const wordsDeclension = {
	day: ['день', 'дня', 'дней'],
	month: ['месяц', 'месяца', 'месяцев'],
}

export const discounts = {
	1: {
		1: 0,
		3: 5,
		6: 10,
		12: 15,
	}
}

export const animationSizes = {
	AnimationSearch: {
		width: '190px',
		height: 'auto'
	},
	AnimationSuccess: {
		width: '170px',
		height: 'auto'
	},
	AnimationFailure: {
		width: '185px',
		height: 'auto'
	},
	AnimationCrown: {
		width: '200px',
		height: 'auto'
	},
	AnimationCup: {
		width: '130px',
		height: 'auto'
	},
	AnimationWin: {
		width: '215px',
		height: 'auto'
	},
	AnimationStar: {
		width: '230px',
		height: 'auto'
	},
	AnimationSettings: {
		width: '135px',
		height: 'auto'
	}
}

export const bgColors = {
	default: [
		'#dbddbb',
		'#6ba587',
		'#d5d88d',
		'#88b884'
	],
	amethyst: [
		'#4f5bd5',
		'#962fbf',
		'#dd6cb9',
		'#fec496'
	],
	calico: [
		'#baa161',
		'#ddb56d',
		'#cea668',
		'#faf4d2'
	],
	cavernPink: [
		'#ecd893',
		'#e5a1d0',
		'#edd594',
		'#d1a3e2'
	],
	cheerfulness: [
		'#efd359',
		'#e984d8',
		'#ac86ed',
		'#40cdde'
	],
	france: [
		'#fbd9e6',
		'#fb9ae5',
		'#d5f7ff',
		'#73caff'
	],
	lightWisteria: [
		'#b493e6',
		'#eab9d9',
		'#8376c2',
		'#e4b2ea'
	],
	malibu: [
		'#679ced',
		'#e39fea',
		'#888dec',
		'#8adbf2'
	],
	monteCarlo: [
		'#85d685',
		'#67a3f2',
		'#8fe1d6',
		'#dceb92'
	],
	perfume: [
		'#b9e2ff',
		'#eccbff',
		'#a2b4ff',
		'#daeacb'
	],
	periwinkleGray: [
		'#efb7dc',
		'#c6b1ef',
		'#b1e9ea',
		'#97beeb'
	],
	pineGlade: [
		'#fbe37d',
		'#336f55',
		'#fff5c5',
		'#7fa381'
	],
	ice: [
		'#b2e3dd',
		'#bbead5',
		'#9fb0ea',
		'#b0cdeb'
	],
	viola: [
		'#f7dd6d',
		'#e96caf',
		'#edac4c',
		'#a464f4'
	],
	wewak: [
		'#e8c06e',
		'#f29ebf',
		'#f0e486',
		'#eaa36e'
	],
	wildWillow: [
		'#f0c07a',
		'#afd677',
		'#e4d573',
		'#7fc289'
	],
	cashmere: [
		'#ffe7b2',
		'#e2c0ff',
		'#ffc3b2'
	],
	coldPurple: [
		'#6c8cd4',
		'#d4a7c9',
		'#b2b1ee'
	],
	coldBlue: [
		'#527bdd',
		'#009fdd',
		'#a4dbff'
	]
}

export const bgColorsArray = [
	'#dbddbb',
	'#6ba587',
	'#d5d88d',
	'#88b884',
	'#4f5bd5',
	'#962fbf',
	'#dd6cb9',
	'#fec496',
	'#baa161',
	'#ddb56d',
	'#cea668',
	'#faf4d2',
	'#ecd893',
	'#e5a1d0',
	'#edd594',
	'#d1a3e2',
	'#efd359',
	'#e984d8',
	'#ac86ed',
	'#40cdde',
	'#fbd9e6',
	'#fb9ae5',
	'#d5f7ff',
	'#73caff',
	'#b493e6',
	'#eab9d9',
	'#8376c2',
	'#e4b2ea',
	'#679ced',
	'#e39fea',
	'#888dec',
	'#8adbf2',
	'#85d685',
	'#67a3f2',
	'#8fe1d6',
	'#dceb92',
	'#b9e2ff',
	'#eccbff',
	'#a2b4ff',
	'#daeacb',
	'#efb7dc',
	'#c6b1ef',
	'#b1e9ea',
	'#97beeb',
	'#fbe37d',
	'#336f55',
	'#fff5c5',
	'#7fa381',
	'#b2e3dd',
	'#bbead5',
	'#9fb0ea',
	'#b0cdeb',
	'#f7dd6d',
	'#e96caf',
	'#edac4c',
	'#a464f4',
	'#e8c06e',
	'#f29ebf',
	'#f0e486',
	'#eaa36e',
	'#f0c07a',
	'#afd677',
	'#e4d573',
	'#7fc289',
	'#ffe7b2',
	'#e2c0ff',
	'#ffc3b2',
	'#6c8cd4',
	'#d4a7c9',
	'#b2b1ee',
	'#527bdd',
	'#009fdd',
	'#a4dbff'
]

export const docs = {
	policy: 'https://giveshare.ru/policy-privacy',
	terms: 'https://giveshare.ru/terms',
	recurrent: 'https://giveshare.ru/offer-recurrent'
}
